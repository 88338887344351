import axios from 'axios';
import {start_loading,stop_loading} from '../loader/loaderAction'
import { USER_UPDATE } from './authTypes';
import {  toast } from 'react-toastify';

const { REACT_APP_BASE_URL } = process.env;

const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Accept':'application/json'
  },
};

export const set_update_user = (user,navigate) => {
  localStorage.setItem('auth',JSON.stringify(user))
  localStorage.setItem('token',user?.token)
  navigate('/');
  return {
    type: USER_UPDATE,
    payload: user,
  };
};


export const login_user = async (user, dispatch, navigate) => {
  dispatch(start_loading());
  
  try {
 
    const res = await axios.post(`${REACT_APP_BASE_URL}auth/login/admin`, JSON.stringify(user),headers);

    if (res?.data?.data) {
      toast.success(res?.data?.message,{toastId: "Loginuser"})
      dispatch(set_update_user(res?.data?.data,navigate))
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    toast(error.response.data.message)
  } finally {
    dispatch(stop_loading());
  }
};

export const get_login_user=()=>{
 let data= localStorage.getItem('auth')
 if(data){
  return data
 } else {return false};

}

export const admin_profile = async (token) => {
  let config = {
    method: 'get',
    url: `${REACT_APP_BASE_URL}user/profile`,
    headers: { 
      'x-access-token': token, 
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios.request(config);
    localStorage.setItem('auth', JSON.stringify(response.data.data));
    return response.data.data; 
  } catch (error) {
    console.log(error);
    throw error; 
  }
};
